import moment from "moment";
moment.updateLocale("en", {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "a few secs",
        ss: "%d secs",
        m: "a min",
        mm: "%d mins",
        h: "an hr",
        hh: "%d hrs",
        d: "a day",
        dd: "%d days",
        w: "a wk",
        ww: "%d wks",
        M: "a mth",
        MM: "%d mths",
        y: "a yr",
        yy: "%d yrs",
    },
});
export { moment };
