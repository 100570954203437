<script setup>
import { reactive } from "vue";
import { moment } from "./shortMoment.js";

require("./api");
const axios = require("axios");

const props = defineProps({
    plantsJson: {
        type: String,
        required: true,
    },
    permsAddFertilizationDate: {
        type: Boolean,
        required: false,
    },
    addFertilizationUrl: {
        type: String,
        required: true,
    },
});

class ToDo {
    /*
    Class used to check if something needs to be done for a plant.
    Used by vue and tailwind for styling plant cards that need something done.
    */
    constructor() {
        this.entries = reactive({});
    }

    addToDo(plantId, task) {
        this.entries[plantId] = this.entries[plantId] ? this.entries[plantId] : {};
        this.entries[plantId][task] = true;
    }

    removeToDo(plantId, task) {
        if (this.entries?.[plantId]?.[task]) {
            delete this.entries[plantId][task];
            if (Object.keys(this.entries[plantId]).length === 0) {
                delete this.entries[plantId];
            }
        }
    }
}

function friendlyOrNull(date) {
    /*
    Returns a friendly date if the param being passed in is a datetime string
    otherwise null
    */
    return date ? moment(date).fromNow() : null;
}

function checkGiveFertilizer(plant) {
    /*
    Check if plant needs fertilizer now, if so return 'now' and add to toDo,
    else return next fertilization date and remove toDo if necessary
    */
    let nextFertilization = plant.next_fertilization;
    let plantId = plant.plant_id;

    if (nextFertilization == null) {
        return null;
    }

    nextFertilization = moment(nextFertilization);
    let timeNow = moment();

    if (nextFertilization.isBefore(timeNow)) {
        toDo.addToDo(plantId, "fertilize");
        return "now";
    } else {
        toDo.removeToDo(plantId, "fertilize");
        return nextFertilization.fromNow();
    }
}

function checkNeedsWatering(plant) {
    /*
    If plant needs watering add to toDo
    return rounded sensorReading
    */
    let wateringLevel = plant.genus.watering_level;
    let sensorReading = plant.sensor_reading.reading;
    let plantId = plant.plant_id;

    if (wateringLevel) {
        if (wateringLevel < sensorReading || sensorReading < 0) {
            toDo.addToDo(plantId, "water");
        } else {
            toDo.removeToDo(plantId, "water");
        }
    }
    return Math.round(sensorReading);
}

function updatePlant(plantId, url, property) {
    /*
    Update plant object when something gets updated
    So far only fertilization
    */
    axios
        .post(url, { plant: plantId })
        .then(() => {
            switch (property) {
                case "fertilization": {
                    // Update timestamp, if the POST is successful
                    // fertilizer_interval is a python timedelta string with only
                    // the day portion not null
                    let fertilizerInterval = parseInt(
                        plants[plantId].genus.fertilizer_interval
                    );
                    plants[plantId].next_fertilization = moment().add(
                        fertilizerInterval,
                        "d"
                    );
                    break;
                }
                default:
                    throw new Error("invalid property, ", property);
            }
        })
        .catch((error) => {
            console.log("error response: ", error);
            throw error;
        });
}

function addFertilizationDate(plantId) {
    /*
    Calls update plant with the 'fertilization' prop
    */
    updatePlant(plantId, props.addFertilizationUrl, "fertilization");
}

function formatText(condition) {
    /*
    Returns tailwind classes for text based on some condition
    */
    return {
        "text-brand-black": !condition,
        "font-light": !condition,
        "text-brand-orange": condition,
        "font-medium": condition,
    };
}

const plants = reactive(JSON.parse(props.plantsJson));

console.log(plants);

const toDo = new ToDo();

console.log(toDo);
</script>

<template>
    <div class="pt-16 md:px-0">
        <div class="mx-auto mb-1.5 max-w-screen-2xl sm:mx-10">
            <h1
                class="
                    font-vollkorn
                    leading-none
                    text-center text-[7rem] text-brand-black
                    xs:text-[8.2rem]
                    sm:text-left sm:text-[10rem]
                "
            >
                Plants
            </h1>
        </div>
        <div class="bg-stripes h-full bg-cover">
            <div class="pt-8 sm:mx-7 sm:flex sm:flex-wrap">
                <div v-for="plant in plants" :key="plant.plant_id">
                    <section
                        class="
                            shadow-sharp
                            border-brand-black
                            relative
                            mx-auto
                            mb-8
                            w-[17.375rem]
                            rounded-sm3
                            border
                            sm:mx-5
                        "
                        :class="{
                            'bg-brand-mint-cream': !toDo.entries[plant.plant_id],
                            'bg-brand-ivory': toDo.entries[plant.plant_id],
                        }"
                    >
                        <div
                            class="
                                absolute
                                border-brand-black
                                bg-brand-black
                                top-[-0.0625rem]
                                left-[-0.0625rem]
                                float-left
                                h-[3.535rem]
                                w-[3.9375rem]
                                rounded-br-sm3 rounded-tl-sm3
                                border
                                p-1
                            "
                        >
                            <h2
                                class="
                                    text-center text-5xl
                                    font-extralight
                                    leading-[2.85rem]
                                    text-white
                                "
                            >
                                {{ plant.plant_id }}
                            </h2>
                        </div>
                        <h2
                            class="
                                font-vollkorn
                                mt-0.5
                                ml-2
                                pl-[4.4rem]
                                text-2xl
                                leading-[1.74rem]
                            "
                        >
                            {{ plant.genus.genus.split(" ")[0] }}<br />
                            {{ plant.genus.genus.split(" ")[1] }}
                        </h2>
                        <div class="flex w-full flex-col gap-4 px-3 pt-3 pb-4">
                            <div class="flex items-start gap-7">
                                <figure
                                    class="
                                        flex
                                        h-10
                                        w-10
                                        flex-shrink-0
                                        items-center
                                        justify-center
                                    "
                                >
                                    <img
                                        src="./assets/images/noun-water-droplets.svg"
                                        alt="Water"
                                    />
                                </figure>
                                <div class="w-[11.5rem]">
                                    <div class="float-left">
                                        <h3
                                            class="text-base"
                                            :class="
                                                formatText(
                                                    toDo.entries?.[plant.plant_id]?.[
                                                        'water'
                                                    ]
                                                )
                                            "
                                        >
                                            <span class="text-xl leading-tight">{{
                                                checkNeedsWatering(plant)
                                            }}</span
                                            >&thinsp;mbar
                                        </h3>
                                        <p
                                            class="
                                                text-brand-black text-[0.625rem]
                                                font-light
                                            "
                                        >
                                            ({{
                                                friendlyOrNull(
                                                    plant.sensor_reading.timestamp
                                                ).toUpperCase()
                                            }})
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-start gap-7">
                                <figure
                                    class="
                                        flex
                                        h-10
                                        w-10
                                        flex-shrink-0
                                        items-center
                                        justify-center
                                    "
                                >
                                    <img
                                        src="./assets/images/image.svg"
                                        alt="Fertilizer"
                                    />
                                </figure>
                                <div>
                                    <h3
                                        class="pb-1 text-base leading-tight"
                                        :class="
                                            formatText(
                                                toDo.entries?.[plant.plant_id]?.[
                                                    'fertilize'
                                                ]
                                            )
                                        "
                                    >
                                        Feed
                                        {{ plant.genus.fertilizer_amount }} strength
                                        <br />
                                        {{ checkGiveFertilizer(plant) }}
                                    </h3>
                                    <form
                                        v-if="permsAddFertilizationDate"
                                        @submit.prevent="
                                            addFertilizationDate(plant.plant_id)
                                        "
                                    >
                                        <button
                                            class="
                                                bg-brand-turquoise
                                                text-brand-black
                                                rounded-full
                                                px-3
                                                py-[0.2rem]
                                                text-[0.625rem]
                                                font-normal
                                            "
                                        >
                                            Update
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
